import { ChatBubbleOvalLeftIcon, EyeIcon } from '@heroicons/react/24/outline'
import { useEffect }                       from 'react'
import { useDispatch, useSelector }        from 'react-redux'
import { useNavigate }                     from 'react-router-dom'
import Pagination                          from '../../components/Pagination/Pagination'
import { SelectOption }                    from '../../components/Select/Select'
import { setForumListRefetch }             from '../../features/forum/communityForumSlice'
import CommunityForumTableItem             from './CommunityForumTableItem'

export default function CommunityForumTable( props: any ): JSX.Element {
  const { categoryId, forumCategories, refetch, sortBy } = props
  const navigate: any                                    = useNavigate()
  const dispatch: any                                    = useDispatch()
  const currentUser: any                                 = useSelector( ( state: any ) => state.currentUser.user )
  const threadList: any                                  = useSelector( ( state: any ) => state.communityForum )
  const sortOptions: any                                 = [
    {
      id:   'latestActivityNewest',
      name: 'Latest Activity: Newest'
    },
    {
      id:   'latestActivityOldest',
      name: 'Latest Activity: Oldest'
    },
    {
      id:   'dateCreatedNewest',
      name: 'Date Created: Newest'
    },
    {
      id:   'dateCreatedOldest',
      name: 'Date Created: Oldest'
    }
  ]
  const selectedOption: any                              = sortOptions.find( ( option: any ) => option.id === sortBy )

  const urlFormat: any = ( paramsString: string = '' ): string => {
    let urlString = '/forum'
    const params: any  = new URLSearchParams( paramsString )

    if ( categoryId ) {
      params.set( 'category', categoryId )
    }

    if ( sortBy && !params.has( 'sortBy' ) ) {
      params.set( 'sortBy', sortBy )
    }

    return params ? `${ urlString }?${ params }` : urlString
  }

  const handleSortChange: any = ( sortById: string ): void => {
    if ( !sortById ) {
      navigate( urlFormat() )
    } else if ( sortBy !== sortById ) {
      navigate( urlFormat( `sortBy=${ sortById }` ) )
    }
  }

  const setCurrentPage: any = ( page: number = 1 ): any => navigate( urlFormat( `page=${ page }` ) )

  useEffect( (): any => {
    let shouldFetch: any = true

    if ( shouldFetch && threadList?.refetch ) {
      refetch()
      dispatch( setForumListRefetch( false ) )
    }

    return (): void => {
      shouldFetch = false
    }
  }, [ threadList ] )

  return (
    <>
      { threadList?.data.length < 1 && <p>No threads yet...</p> }

      { threadList?.data.length > 0 &&
        <table className="documents-table w-full text-sm">
          <thead>
            <tr>
              <th className="w-auto border-b-2 border-gray-table-border py-3 text-left">Thread</th>
              <th className="w-[55px] border-b-2 border-gray-table-border py-3">
                <EyeIcon className="w-5 h-5 mx-auto" />
              </th>
              <th className="w-[55px] border-b-2 border-gray-table-border py-3">
                <ChatBubbleOvalLeftIcon className="w-5 h-5 mx-auto" />
              </th>
              <th className="w-[200px] border-b-2 border-gray-table-border py-3 text-right">
                <SelectOption
                  setSelected={ handleSortChange }
                  selected={ selectedOption }
                  placeholder={ selectedOption.name }
                  selectClasses="border-none focus:ring-0"
                  options={ sortOptions.map( ( sort: any ) => {
                    return { key: sort.id, value: sort.name }
                  } ) }
                />
              </th>
              { currentUser.admin &&
                <th className="w-0 border-b-2 border-gray-table-border py-3"></th>
              }
            </tr>
          </thead>
          <tbody>
            { threadList.data.map( ( forumThread: any, index: any ) =>
                                     <CommunityForumTableItem
                                       forumCategory={ forumCategories.find( ( c: any ): boolean => c.id === forumThread.category_id ) }
                                       key={ index }
                                       forumThread={ forumThread }
                                       refetch={ refetch }
                                       isAdmin={ currentUser.admin }
                                     /> ) }
          </tbody>
        </table>
      }

      { ( threadList?.pagination.totalPages > 0 ) &&
        <Pagination
          className="justify-content-center"
          currentPage={ threadList.pagination.page }
          totalCount={ threadList.pagination.totalResults }
          pageSize={ threadList.pagination.perPage }
          onPageChange={ ( page: any ) => setCurrentPage( page ) }
        />
      }
    </>
  )
}
