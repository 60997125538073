import { assetFilePath } from '../../utilities'
import { Image }         from '../Image'

interface ThreadSingleAttachment
{
  attachment: any,
  classes?: string
  handleAttachment: () => any
}

export default function CommunityForumThreadSingleAttachment( props: ThreadSingleAttachment ): JSX.Element {
  const { attachment, classes, handleAttachment } = props
  const appCdnUrl: string = process.env.REACT_APP_CDN + ( process.env.REACT_APP_CDN.slice( -1 ) === '/' ? '' : '/' )

  return (
    <div className={ classes ?? 'media-wrapper flex items-center justify-center bg-black cursor-pointer' } onClick={ handleAttachment }>
      <Image
        src={ appCdnUrl + attachment.url }
        alt={ attachment.name }
        defaultSrc={ assetFilePath( attachment.file_ext ) }
      />
    </div>
  )
}
