import { useLazyQuery, useMutation }                  from '@apollo/client'
import { ArrowUturnLeftIcon, TrashIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { UploadCloud }                                from 'lucide-react'
import { useEffect, useRef, useState }                from 'react'
import Dropzone                                       from 'react-dropzone'
import { useDispatch, useSelector }                   from 'react-redux'
import { useNavigate }                                from 'react-router-dom'
import { Button, TextField }                          from '../../components'
import { Editor }                                     from '../../components/Editor/Editor'
import { Image }                                      from '../../components/Image'
import { LoadingEllipsis }                            from '../../components/Loaders/Loaders'
import { SelectOption }                               from '../../components/Select/Select'
import {
    setForumCategories,
    setForumListRefetch,
    setThreadAttachmentsRefetch
}                                                     from '../../features/forum/communityForumSlice'
import {
    setToasts,
    showToaster
}                                                     from '../../features/toaster/toasterSlice'
import {
    CREATE_FORUM_ATTACHMENT,
    CREATE_FORUM_THREAD,
    DELETE_FORUM_ATTACHMENT,
    DELETE_FORUM_THREAD,
    GET_FORUM_CATEGORIES,
    UPDATE_FORUM_THREAD
}                                                     from '../../graphql/communityForum'
import { assetFilePath, truncateText }                from '../../utilities'
import Api                                            from '../../utilities/axios'

export default function CommunityForumThreadForm( props: any ): JSX.Element {
    const { forumThread, question, isModal, refetch } = props
    const navigate: any                               = useNavigate()
    const dispatch: any                               = useDispatch()
    const uploadApiUrl: string                        = process.env.REACT_APP_UPLOAD_HOST
    const appCdnUrl: string                           = process.env.REACT_APP_CDN + ( process.env.REACT_APP_CDN.slice( -1 ) === '/' ? '' : '/' )
    const forumCategories: any                        = useSelector( ( state: any ): any => state.communityForum.categories )
    const currentTenant: any                          = useSelector( ( state: any ): any => state.currentTenant.tenant )
    const currentUser: any                            = useSelector( ( state: any ): any => state.currentUser.user )
    const [ loading, setLoading ]                     = useState( false )
    const [ categoryError, setCategoryError ]         = useState( false )
    const [ titleError, setTitleError ]               = useState( false )
    const [ bodyError, setBodyError ]                 = useState( false )
    const [ filesToUpload, setFilesToUpload ]         = useState( [] )
    const [ title, setTitle ]                         = useState( forumThread?.title ?? ( question ? truncateText( question ) : '' ) )
    const [ categoryId, setCategoryId ]               = useState( forumThread?.category_id ?? '' )
    const [ body, setBody ]                           = useState( forumThread?.body ?? ( question ? `<p>${ question }</p>` : '<p></p>' ) )
    const filePreviewContainer: any                   = useRef( null )

    const [ getForumCategories ] = useLazyQuery( GET_FORUM_CATEGORIES, {
        onCompleted: ( { forumCategories }: any ): void => {
            dispatch( setForumCategories( forumCategories ) )
            setLoading( false )
        }
    } )

    const [ updateForumThread, { loading: updating } ] = useMutation( UPDATE_FORUM_THREAD, {
        onCompleted: (): void => {
            dispatchMessage( 'success', 'Question updated!' )
            if ( !filesToUpload.length ) {
                refetch()
            }
            dispatch( setForumListRefetch( true ) )
        }
    } )

    const [ createForumThread, { loading: creating } ] = useMutation( CREATE_FORUM_THREAD, {
        onCompleted: (): void => {
            dispatchMessage( 'success', 'New question added!' )
            dispatch( setForumListRefetch( true ) )
        }
    } )

    const [ createForumAttachment ] = useMutation( CREATE_FORUM_ATTACHMENT, {
        onCompleted: (): void => handleAttachmentSuccessAction( 'Attachment uploaded!' ),
        onError:     (): void => dispatchMessage( 'error', 'Attachment NOT uploaded!' )
    } )

    const [ deleteForumAttachment, { loading: deletingAttachment } ] = useMutation( DELETE_FORUM_ATTACHMENT, {
        onCompleted: ( { deleteForumAttachment }: any ): any => {
            if ( deleteForumAttachment.__typename === 'FieldError' ) {
                dispatchMessage( 'error', 'Attachment not deleted. Server error.' )
            } else {
                handleAttachmentSuccessAction( 'Attachment deleted successfully' )
            }
        },
        onError:     (): void => dispatchMessage( 'error', 'Attachment not deleted. Please try again' )
    } )

    const [ deleteForumThread, { loading: deleting } ] = useMutation( DELETE_FORUM_THREAD, {
        onCompleted: (): void => {
            dispatchMessage( 'success', 'Question deleted' )
            dispatch( setForumListRefetch( true ) )
            navigate( '/forum' )
        }
    } )

    const handleAttachmentSuccessAction: any = ( message: string ): void => {
        refetch()
        dispatchMessage( 'success', message )
        dispatch( setThreadAttachmentsRefetch( true ) )
    }

    const resetErrors: any = (): void => {
        setTitleError( false )
        setCategoryError( false )
        setBodyError( false )
    }

    const handleSubmit: any = async (): Promise<void> => {
        resetErrors()

        if ( !title.length ) {
            setTitleError( true )
            return
        }

        if ( !categoryId.length ) {
            setCategoryError( true )
            return
        }

        if ( !body.replace( /(<([^>]+)>)/gi, '' ).length ) {
            setBodyError( true )
            return
        }

        const formData: any = {
            title:       title,
            category_id: categoryId,
            body:        body
        }

        let threadIdToUpdate: string | null = forumThread ? forumThread.id : null

        if ( threadIdToUpdate ) {
            formData.id = forumThread.id
            updateForumThread( { variables: { input: formData } } )
        } else {
            const { data } = await createForumThread( { variables: { input: formData } } )

            if ( data?.createForumThread?.data?.id ) {
                threadIdToUpdate = data.createForumThread.data.id
            }
        }

        if ( threadIdToUpdate ) {
            await uploadFiles( threadIdToUpdate )
            setFilesToUpload( [] )
            navigate( `/forum/edit/${ threadIdToUpdate }` )
        } else {
            dispatchMessage( 'error', 'Unknown error!' )
        }
    }

    const handleDelete: any = ( e: any ): void => {
        e.preventDefault()

        if ( window.confirm( 'Are you sure you want to delete this thread?' ) && forumThread ) {
            deleteForumThread( { variables: { input: { id: forumThread.id } } } )
        }
    }

    const handleDeleteAttachment: any = async ( attachment: any ): Promise<void> => {
        if ( !attachment || !attachment.id || deletingAttachment ) return

        if ( window.confirm( 'Are you sure you want to delete this attachment?' ) ) {
            deleteForumAttachment( { variables: { input: { id: attachment.id } } } )
        }
    }

    const dispatchMessage: any = ( type: string, message: string ): void => {
        dispatch( setToasts( [ { id: '1', type, message } ] ) )
        dispatch( showToaster( true ) )
    }

    const handleSetFiles: any = ( files: any ): void => {
        const initialFilesQuantity: number = files.length
        const loadableFiles: any           = filesToUpload.filter( ( f: any ): any => !f.deleted )
        files                              = files.filter( ( f: any ): any => f.size < 1048576 )

        if ( files.length !== initialFilesQuantity ) {
            alert( 'Max file size is 1MB. Some of files has been removed.' )
        }

        if ( forumThread ) {
            if ( ( loadableFiles.length >= 5 ) || ( forumThread.attachments.length >= 5 ) ) {
                return alert( 'Limit of 5 files reached' )
            }

            if ( ( loadableFiles.length + files.length ) > ( 5 - forumThread.attachments.length ) ) {
                files.splice( Math.max( 0, ( 5 - forumThread.attachments.length ) ) )
            }
        } else {
            if ( loadableFiles.length + files.length > 5 ) {
                files.splice( Math.max( 0, 5 - loadableFiles.length ) )
            }
        }

        setFilesToUpload( [ ...filesToUpload, ...files ] )
    }

    const handleRemoveFileToUpload: any = ( index: number ): void => {
        setFilesToUpload( [ ...filesToUpload.map( ( f: any, i: number ): any => {
            if ( index === i ) {
                f.deleted = true
            }

            return f
        } ) ] )
    }

    const uploadFiles: any = async ( threadId: string ): Promise<void> => {
        if ( !filesToUpload.length ) return

        try {
            for await ( const file of filesToUpload ) {
                if ( !file.deleted ) {
                    await uploadAttachment( threadId, file )
                }
            }
        } catch ( e ) {
            dispatchMessage( 'error', 'Upload failed' )
        }
    }

    const uploadAttachment: any = async ( threadId: string, file: any ): Promise<void> => {
        setLoading( true )

        const formData: FormData = new FormData()

        formData.append( 'file', file )
        formData.append( 'is_public', 'true' )
        formData.append( 'folder', currentTenant.uuid + '/forum/attachments/' + threadId )

        try {
            const { data } = await Api( uploadApiUrl, 'multipart/form-data' ).post( '/', formData )

            if ( data ) {
                const filename: any      = file.name.replace( /\.[^/.]+$/, '' )
                const fileTypeArray: any = file.type.split( '/' )

                createForumAttachment( {
                                           variables: {
                                               input: {
                                                   'thread_id': threadId,
                                                   'source':    'thread',
                                                   'type':      fileTypeArray[ 0 ],
                                                   'file_ext':  fileTypeArray[ fileTypeArray.length - 1 ],
                                                   'file_type': file.type,
                                                   'name':      filename.length ? filename : 'no_name',
                                                   'url':       data[ 0 ].path
                                               }
                                           }
                                       } )
            }
        } catch ( error ) {
            console.error( `Error uploading file (${ file.name }):`, error )
        }

        setLoading( false )
    }

    const previewFile: any = ( file: any, index: number ): any => {
        if ( filePreviewContainer.current && file.type.startsWith( 'image/' ) ) {
            const preview: any = filePreviewContainer.current.querySelector( `.image-preview-${ index }` )
            const reader: any  = new FileReader()

            if ( preview ) {
                reader.addEventListener( 'load', (): any => preview.src = reader.result, false )
                reader.readAsDataURL( file )
            }
        }
    }

    const handleGoBack: any = (): any => navigate( -1 )

    useEffect( (): any => {
        let shouldFetch: boolean = true

        if ( shouldFetch && !forumCategories.length ) {
            setLoading( true )
            getForumCategories()
        }

        return (): void => {
            shouldFetch = false
        }
    }, [ forumCategories ] )

    return (
        <>
            { !isModal &&
              <div className="w-24 mb-8">
                  <Button label="Back" onClick={ handleGoBack } icon={ <ArrowUturnLeftIcon className="w-4 h-4" /> } />
              </div>
            }

            { ( updating || creating || deleting || loading ) &&
              <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" />
            }

            { ( !updating && !creating && !deleting && !loading ) &&
              <>
                  <div className="flex flex-wrap items-center w-full mb-3">
                      { !isModal && <div className="w-1/3">Title</div> }
                      <div className={ isModal ? 'w-full' : 'w-2/3' }>
                          <TextField
                              label={ isModal ? 'Title' : '' }
                              value={ title }
                              name="title"
                              type="text"
                              onChange={ setTitle }
                              errorField={ titleError ? 'title' : '' }
                              isRequired
                          />
                      </div>
                  </div>

                  <div className="flex flex-wrap items-center w-full mb-3">
                      { !isModal && <div className="w-1/3">Category</div> }
                      <div className={ isModal ? 'w-full' : 'w-2/3' }>
                          <SelectOption
                              required
                              bg="white"
                              label={ isModal ? 'Category' : '' }
                              selected={ categoryId }
                              setSelected={ setCategoryId }
                              errorField={ categoryError }
                              options={ forumCategories.map( ( c: any ): any => ( { key: c.id, value: c.name } ) ) }
                          />
                      </div>
                  </div>

                  <div className="flex flex-wrap items-center w-full mb-3">
                      { !isModal && <div className="w-1/3">Details</div> }
                      <div className={ `forum-lexical ${ isModal ? 'w-full' : 'w-2/3' }` }>
                          <Editor
                              // @ts-ignore
                              initialHtml={ body }
                              actionNode={ ( content: any ): void => setBody( content ) }
                              errorField={ bodyError }
                              isRequired
                          />
                      </div>
                  </div>

                  { !isModal &&
                    <>
                        <div className="flex flex-wrap items-center w-full mb-3">
                            <div className="w-1/3">Attachment</div>
                            <div className="w-2/3">
                                <div className="cursor-pointer no-select p-6 border bg-gray-100 rounded-md">
                                    <Dropzone onDrop={ ( attachedFiles: any ): any => handleSetFiles( attachedFiles ) } multiple={ true }>
                                        { ( { getRootProps, getInputProps }: any ): any => (
                                            <section>
                                                <div { ...getRootProps() }>
                                                    <input { ...getInputProps() } />

                                                    <div className="flex items-center justify-center flex-col space-y-2">
                                                        <UploadCloud className="w-12 h-12" />
                                                        <p className="text-sm font-medium">Drag 'n' drop or click to select file</p>
                                                    </div>
                                                </div>
                                            </section>
                                        ) }
                                    </Dropzone>
                                </div>

                                { ( filesToUpload.length > 0 ) &&
                                  <div ref={ filePreviewContainer } className="mt-4 pb-2 flex items-center flex-wrap gap-3">
                                      { filesToUpload.map( ( file: any, index: number ): any => {
                                          return ( !file.deleted &&
                                                   <div className="flex flex-col w-16 mr-2 h-auto relative rounded-md relative" key={ index }>
                                                       <button
                                                           title="Remove"
                                                           className="absolute -right-1 -top-1 bg-white shadow rounded-full"
                                                           onClick={ (): any => handleRemoveFileToUpload( index ) }
                                                       >
                                                           <XCircleIcon className="w-4 h-4" />
                                                       </button>

                                                       <Image
                                                           klass={ `image-preview-${ index }` }
                                                           src={ previewFile( file, index ) }
                                                           defaultSrc={ assetFilePath( file.type.split( '/' ).pop() ) }
                                                       />

                                                       <small className="text-xs truncate w-16">{ file.name }</small>
                                                   </div> )
                                      } ) }
                                  </div>
                                }
                            </div>
                        </div>

                        { ( forumThread?.attachments?.length > 0 ) &&
                          <div className="flex flex-wrap items-center w-full mb-3">
                              <div className="w-full border-b my-4">Media:</div>
                              <div className="grid grid-cols-4 lg:grid-cols-8 gap-2 lg:gap-4 w-full">
                                  { forumThread.attachments.map( ( attachment: any ): any =>
                                                                     <div className="relative flex items-center justify-center border w-full" key={ attachment.id }>
                                                                         <button
                                                                             title="Delete"
                                                                             className="absolute -right-2 -top-2 bg-red-500 text-white rounded-full"
                                                                             onClick={ (): any => handleDeleteAttachment( attachment ) }
                                                                             disabled={ deletingAttachment }
                                                                         >
                                                                             <TrashIcon className="w-6 h-6 p-1" />
                                                                         </button>

                                                                         <div className="flex flex-col">
                                                                             <div className="flex items-center justify-center w-16 h-16 lg:w-24 lg:h-24">
                                                                                 <Image
                                                                                     klass="max-w-16 max-h-16 lg:max-w-24 lg:max-h-24"
                                                                                     src={ appCdnUrl + attachment.url }
                                                                                     alt={ attachment.name }
                                                                                     defaultSrc={ assetFilePath( attachment.file_ext ) }
                                                                                 />
                                                                             </div>
                                                                             <small className="text-xs truncate w-16 lg:w-24">{ attachment.name }</small>
                                                                         </div>
                                                                     </div> ) }
                              </div>
                          </div>
                        }
                        </>
                  }

                  <div className={ `flex flex-wrap items-center mt-6 ${ isModal ? '' : 'border-t-2 border-gray-300' }` }>
                      <div className={ `flex ${ isModal ? 'w-full' : 'pt-5' }` }>
                          <Button label={ `${ isModal ? 'Ask question now!' : 'Submit' }` } onClick={ handleSubmit } />

                          { forumThread && currentUser.admin &&
                            <button
                                className="gap-x-2 px-3 py-1.5 text-sm w-full text-white font-medium flex items-center justify-center rounded-full text-center text-sm ml-2 bg-red-700 hover:bg-red-500"
                                onClick={ handleDelete }
                            >
                                <TrashIcon className="w-4 h-4" />
                                <span>Delete</span>
                            </button>
                          }
                      </div>
                  </div>
              </>
            }
            </>
    )
}
