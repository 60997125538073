import React, { useState } from "react";
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { GENERATE_UUID } from '../../utilities';

/**
 * TODO: TextField component
 *
 * @param {*} props
 * @returns
 */
export const TextField = (props) => {
   // const [value, setValue] = useState(props.value)
    const {
        label,
        id,
        placeholder,
        onChange,
        onBlur,
        type,
        errorField,
        errorMessage,
        name,
        value
    } = props;

    // Use custom id or the default
    const componentId = id || GENERATE_UUID();

    const asTextMarkup = () => (
         <div className={classnames('relative z-0 w-full group', {
            "bg-red-200": errorMessage || errorField
         })}>
            <input
                required={props.isRequired}
                type={type}
                name={name}
                value={value}
                placeholder={placeholder}
                id={componentId}
                onChange={({ target }) => onChange(target.value)}
                //onBlur={onBlur}
                autoFocus={ props.autoFocus }
                className={ classnames('block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer', {
                    "border-red-600": errorField === name
                })}
            />
            <label htmlFor={componentId} className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                { label }
            </label>
        </div>
    )

    const asTextAreaMarkup = () => (
        <div>
            <label htmlFor={componentId} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                { label }
            </label>
            <textarea
                required={props.isRequired}
                name={name}
                placeholder={placeholder}
                id={componentId}
                onChange={({ target }) => onChange(target.value)}
                //onBlur={onBlur}
                value={value}
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
        </div>
    )

    return (
        <div className="flex-1">
            { props.type == "textarea" ? asTextAreaMarkup() : asTextMarkup() }
            <p className={classnames("text-sm mt-1 h-[3px]", {
                "visible text-red-600": errorMessage,
                invisible: !errorMessage,
            })}>{ errorMessage }</p>
        </div>
    );
}

TextField.defaultProps = {
    placeholder: " ",
    value: "",
    isRequired: false,
    autoFocus: false,
};

TextField.propTypes = {
    autoFocus: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    errorMessage: PropTypes.string,
    isRequired: PropTypes.bool,

    /** Should be the same name as `name` prop */
    errorField: PropTypes.string,

    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    //autoComplete: PropTypes.oneOf(['off']),
    type: PropTypes.oneOf(['text', 'textarea', 'email', 'password', 'email']).isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func
};