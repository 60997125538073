import React from 'react';

interface SelectOptionProps {
    errorField?: boolean;
    errorMessage?: string;
    required?: boolean;
    name?: string;
    label?: string;
    id?: string | number;
    selected?: string | number;
    options: { key: string | number; value: string | number }[];
    setSelected: (data: any) => void;
    bg?: string
    placeholder?: string
    selectClasses?: string
}

export const SelectOption = ( props: SelectOptionProps ) => {
  const { errorField, errorMessage, name, required, label, id, selected, options, setSelected } = props;

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelected(selectedValue);
  };

  const selectClasses: string = props.selectClasses ?? `bg-${ props.bg ?? 'gray-50' } border ${ errorField ? 'border-red-500' : 'border-gray-300' } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`

  return (
    <div>
      {label && (
        <label htmlFor={id?.toString() || 'select_option'} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          {label}
        </label>
      )}
      <select
        id={id?.toString() || 'select_option'}
        value={selected}
        name={ name }
        required={ required }
        onChange={handleSelectChange}
        className={ selectClasses }
      >
        <option value="">{ props.placeholder ?? 'Please choose...' }</option>
        {options.map((option) => (
          <option key={option.key} value={option.key}>
            {option.value}
          </option>
        ))}
      </select>
    </div>
  );
};
