import { FolderIcon, FolderOpenIcon, ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { useState }                                                      from 'react'
import FolderItemChildren                                                from './DocumentFolders/FolderItemChildren'
import ViewFolder                                                        from './DocumentFolders/ViewFolder'
import NewFolder                                                         from './DocumentFolders/NewFolder'
import EditFolder                                                        from './DocumentFolders/EditFolder'
import ViewFile                                                          from './DocumentFiles/ViewFile'
import NewFile                                                           from './DocumentFiles/NewFile'
import EditFile                                                          from './DocumentFiles/EditFile'
import Home                                                              from './Home'
import { Routes, Route, Link }                                           from 'react-router-dom'

export default function DocumentsTable(): JSX.Element {
  const [ isRootOpen, setRootOpen ] = useState( true )

  const toggleRootOpen = () => setRootOpen( !isRootOpen )

  return (
    <div className="grid grid-cols-[auto_1fr] gap-4 bg-white border border-gray-200 mb-4 text-sm">
      <nav className="p-4 leading-loose text-blue-secondary border-r border-gray-200">
        <ul className="pl-0 mb-0 list-none">
          <div className="folder-item">
            <div role="group" className="flex items-center">
              <button type="button" className="p-0 flex items-center" onClick={ toggleRootOpen }>
                {
                  isRootOpen
                  ? <ChevronDownIcon className="w-3 h-4" />
                  : <ChevronRightIcon className="w-3 h-4" />
                }
              </button>
              <Link to={ `/documents` }>
                <button type="button" className="pl-1 flex items-center hover:text-blue-secondary-darker">
                  {
                    isRootOpen
                    ? <FolderOpenIcon className="w-5 h-5" />
                    : <FolderIcon className="w-5 h-5" />
                  }
                  <span className="pl-1">Home</span>
                </button>
              </Link>
            </div>
          </div>
          <div className={ isRootOpen ? 'flex' : 'hidden' }>
            <FolderItemChildren folderId={ null } />
          </div>
        </ul>
      </nav>

      {/** TODO: Move these routes to App.js */}
      <main className="p-4">
        <Routes>
          <Route path="/" element={ <Home /> } />
          {/** TODO: This should be a query string ie /?page=2. "/:value" is used for IDs only */}
          <Route path="/:page?" element={ <Home /> } />

          <Route path="/folder/new" element={ <NewFolder /> } />
          <Route path="/folder/new/:unique_id/new" element={ <NewFolder /> } />
          <Route path="/folder/edit/:unique_id/edit" element={ <EditFolder /> } />
          <Route path="/folder/:unique_id" element={ <ViewFolder /> } />

           {/** TODO: This and others should be /folder/:unique_id?page=2 */}
          <Route path="/folder/:page?/:unique_id" element={ <ViewFolder /> } />

          <Route path="/file/new" element={ <NewFile /> } />
          <Route path="/file/new/:unique_id/new" element={ <NewFile /> } />
          <Route path="/file/edit/:page?/:unique_id/edit" element={ <EditFile /> } />
          <Route path="/file/:unique_id" element={ <ViewFile /> } />
          <Route path="/file/:page?/:unique_id" element={ <ViewFile /> } />
        </Routes>
      </main>
    </div>
  )
}
