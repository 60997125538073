import { useLazyQuery, useQuery }                  from '@apollo/client'
import { useEffect, useState }                     from 'react'
import { useDispatch, useSelector }                from 'react-redux'
import { useNavigate, useParams }                  from 'react-router-dom'
import CommunityForumThreadConversationTab         from '../../components/Forum/CommunityForumThreadConversationTab'
import CommunityForumThreadMediaTab                from '../../components/Forum/CommunityForumThreadMediaTab'
import { LoadingEllipsis }                         from '../../components/Loaders/Loaders'
import { setForumRanks }                           from '../../features/forum/communityForumSlice'
import { setToasts, showToaster }                  from '../../features/toaster/toasterSlice'
import { GET_FORUM_RANKS, GET_FORUM_THREAD_BY_ID } from '../../graphql/communityForum'
import MainLayout                                  from '../../layouts/MainLayout'

export default function CommunityForumThread(): JSX.Element {
  const { thread_id }                   = useParams()
  const dispatch: any                   = useDispatch()
  const navigate: any                   = useNavigate()
  const forumRanks: any                 = useSelector( ( state: any ): any => state.communityForum.ranks )
  const threadsList: any                 = useSelector( ( state: any ): any => state.communityForum.data )
  const [ forumThread, setForumThread ] = useState( null )
  const [ activeTab, setActiveTab ]     = useState( 'conversation' )
console.log(threadsList)
  const { loading: loadingThread, error: errorLoadingThread } = useQuery( GET_FORUM_THREAD_BY_ID, {
    notifyOnNetworkStatusChange: true,
    variables:                   { input: { id: thread_id } },
    onCompleted:                 async ( { forumThreadById }: any ): Promise<void> => {
      if ( !forumThreadById ) {
        navigate( '/forum' )
        dispatchMessage( 'error', 'Question not found!' )
      } else {
        setForumThread( forumThreadById )
      }
    }
  } )

  const [ getRanks, { loading: loadingRanks, error: errorLoadingRanks } ] = useLazyQuery( GET_FORUM_RANKS, {
    notifyOnNetworkStatusChange: true,
    onCompleted:                 ( { forumRanks }: any ): any => dispatch( setForumRanks( forumRanks ) )
  } )

  const dispatchMessage: any = ( type: string, message: string ): void => {
    dispatch( setToasts( [ { id: '1', type, message } ] ) )
    dispatch( showToaster( true ) )
  }

  const handleGoBack: any = (): any => navigate( -1 )

  useEffect( (): any => {
    let shouldFetch: boolean = true

    if ( shouldFetch && !forumRanks?.length ) {
      getRanks()
    }

    return (): void => {
      shouldFetch = false
    }
  }, [ forumThread ] )

  return (
    <MainLayout>
      { ( loadingThread || loadingRanks ) && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4 mt-4" /> }
      { ( !loadingThread && !loadingRanks ) &&
        <div className="max-w-screen-3xl mx-auto w-full">
          <div className="flex-grow lg:p-10 p-3 lg:space-y-8 space-y-3">
            { forumThread && forumRanks &&
              <>
              <div className="col flex justify-between w-full -mb-4">
                  <div className="flex rounded-full text-sm font-medium text-white overflow-hidden">
                    <button
                      type="button"
                      className="w-full h-full px-3 py-1 flex items-center justify-center bg-blue-600 hover:bg-blue-500"
                      onClick={ handleGoBack }
                    >
                        <span>Back</span>
                    </button>
                  </div>

                  <div className="flex rounded-full text-sm font-medium text-white overflow-hidden">
                    <button
                      type="button"
                      className="w-full h-full px-3 py-1 flex items-center justify-center bg-blue-600 hover:bg-blue-500"
                      onClick={ (): void => setActiveTab( 'conversation' ) }
                    >
                      <span>Conversation</span>
                    </button>
                    <button
                      type="button"
                      className="w-full h-full px-3 py-1 flex items-center justify-center bg-blue-600 hover:bg-blue-500"
                      onClick={ (): void => setActiveTab( 'media' ) }
                    >
                      <span>Media</span>
                    </button>
                  </div>
                </div>

                { ( activeTab === 'conversation' ) &&
                  <CommunityForumThreadConversationTab forumThread={ forumThread } forumRanks={ forumRanks } />
                }

                { ( activeTab === 'media' ) &&
                  <CommunityForumThreadMediaTab threadId={ forumThread.id } />
                }
              </>
            }

            { errorLoadingRanks &&
              <div className="flex flex-col w-full bg-white rounded-xl p-4 lg:p-5">
                <b>Error occurred when fetching Forum Ranks:</b>
                { errorLoadingRanks.graphQLErrors.map( ( { message }: any, i: number ): any => (
                  <div key={ ( i + 1000 ) } className="flex w-full"><span>{ message }</span></div>
                ) ) }
              </div>
            }

            { errorLoadingThread &&
              <div className="flex flex-col w-full bg-white rounded-xl p-4 lg:p-5">
                <b>Error occurred when fetching Forum Thread:</b>
                { errorLoadingThread.graphQLErrors.map( ( { message }: any, i: number ): any => (
                  <div key={ i } className="flex w-full"><span>{ message }</span></div>
                ) ) }
              </div>
            }
          </div>
        </div>
      }
    </MainLayout>
  )
}
