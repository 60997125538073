import { useMutation }                 from '@apollo/client'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/20/solid'
import { formatDistance }              from 'date-fns'
import { useDispatch }                 from 'react-redux'
import { Link }                        from 'react-router-dom'
import { LoadingEllipsis }             from '../../components/Loaders/Loaders'
import { setToasts, showToaster }      from '../../features/toaster/toasterSlice'
import { UPDATE_FORUM_THREAD }         from '../../graphql/communityForum'

export default function CommunityForumTableItem( props: any ): JSX.Element {
  const { isAdmin, forumCategory, forumThread, refetch } = props
  const dispatch: any                                    = useDispatch()

  const [ deleteForumThread, { loading: deleting } ] = useMutation( UPDATE_FORUM_THREAD, {
    onCompleted: () => {
      dispatchMessage( 'success', 'Question deleted!' )
      refetch()
    }
  } )

  const dispatchMessage = ( type: string, message: string ) => {
    dispatch( setToasts( [ { id: '1', type, message } ] ) )
    dispatch( showToaster( true ) )
  }

  const handleDelete: any = ( e: any ): void => {
    e.preventDefault()

    if ( window.confirm( 'Are you sure you want to delete this Question?' ) ) {
      deleteForumThread( { variables: { input: { id: forumThread.id, deleted: true } } } )
    }
  }

  return (
    <>
      { deleting &&
        <tr className="relative h-32 border-b border-gray-table-border">
          <td>
            <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" />
            </div>
          </td>
        </tr>
      }
      { !deleting &&
        <tr>
          <td className="py-3 text-left">
            <div>
              <Link to={ '/forum/' + forumThread.id } className="text-blue-500 hover:text-blue-800">
                <span>{ forumThread.title }</span>
              </Link>
              <div>in { forumCategory.name } by { forumThread.author.name }</div>
            </div>
          </td>
          <td className="py-3 text-center">{ forumThread.views }</td>
          <td className="py-3 text-center">{ forumThread.replies_count ?? 0 }</td>
          <td className="py-3 text-right">
            <div>
              <div>{ forumThread.last_reply?.author?.name ? forumThread.last_reply.author.name : forumThread.author.name }</div>
              <div>
                { formatDistance(
                  new Date( forumThread.last_reply?.updated_at ? forumThread.last_reply.updated_at : forumThread.created_at ),
                  new Date(),
                  { addSuffix: true }
                ) }
              </div>
            </div>
          </td>
          { isAdmin &&
            <td className="p-3 r">
              <div className="flex justify-end ml-4">
                <Link to={ `/forum/edit/${ forumThread.id }` }>
                  <button type="button" className="flex p-0 mr-3 text-blue-secondary hover:text-blue-secondary-darker">
                    <PencilSquareIcon className="w-4 h-4" />
                  </button>
                </Link>

                <button
                  className="p-0 float-right hover:text-red-600"
                  color="default"
                  onClick={ handleDelete }
                >
                  <TrashIcon className="w-4 h-4" />
                </button>
              </div>
            </td>
          }
        </tr>
      }
    </>
  )
}
