import { useLazyQuery }                                           from '@apollo/client'
import { UserCircleIcon }                                         from '@heroicons/react/24/outline'
import { ArrowDownTrayIcon }                                      from '@heroicons/react/24/solid'
import { format, parseISO }                                       from 'date-fns'
import React, { useEffect, useState }                             from 'react'
import { useSelector }                                            from 'react-redux'
import { Link, useSearchParams }                                  from 'react-router-dom'
// @ts-ignore
import { ReactComponent as DotIcon }                              from '../../assets/svgs/dot.svg'
import { GET_FORUM_THREAD_REPLIES, GET_FORUM_THREAD_REPLY_BY_ID } from '../../graphql/communityForum'
import { assetFilePath, prefetchFileAndDownload }                 from '../../utilities'
import { Image }                                                  from '../Image'
import { LoadingEllipsis }                                        from '../Loaders'
import CommunityForumModal                                        from '../Modal/CommunityForumModal'
import Pagination                                                 from '../Pagination/Pagination'
import { SelectOption }                                           from '../Select/Select'
import CommunityForumSingleReply                                  from './CommunityForumSingleReply'
import CommunityForumThreadReply                                  from './CommunityForumThreadReply'
import CommunityForumThreadReplyForm                              from './CommunityForumThreadReplyForm'
import CommunityForumThreadSingleAttachment                       from './CommunityForumThreadSingleAttachment'

interface ConversationProps
{
  forumThread: any
  forumRanks: any
}

export default function CommunityForumThreadConversationTab( props: ConversationProps ): JSX.Element {
  const { forumThread, forumRanks }                                       = props
  const [ searchParams, setSearchParams ]                                 = useSearchParams()
  const currentUser: any                                                  = useSelector( ( state: any ): any => state.currentUser.user )
  const [ sortBy, setSortBy ]                                             = useState( searchParams.get( 'sortBy' ) ?? 'newestCreated' )
  const [ showAttachment, setShowAttachment ]                             = useState( null )
  const [ replyFormOpen, setReplyFormOpen ]                               = useState( null )
  const [ forumThreadReplies, setForumThreadReplies ]                     = useState( null )
  const [ forumThreadRepliesPagination, setForumThreadRepliesPagination ] = useState( null )
  const [ bestReply, setBestReply ]                                       = useState( null )
  const [ hasReplies, setHasReplies ]                                     = useState( false )
  const appCdnUrl: string                                                 = process.env.REACT_APP_CDN + ( process.env.REACT_APP_CDN.slice( -1 ) === '/' ? '' : '/' )

  const sortOptions: any    = [
    { id: 'newestCreated', name: 'Newest' },
    { id: 'oldestCreated', name: 'Oldest' }
    // { id: 'bestUpvote', name: 'Upvote' } // todo: develop this feature first
  ]
  const selectedOption: any = sortOptions.find( ( option: any ): any => option.id === sortBy )

  const [ getForumThreadBestReply, { loading: loadingBestReply } ] = useLazyQuery( GET_FORUM_THREAD_REPLY_BY_ID, {
    notifyOnNetworkStatusChange: true,
    onCompleted:                 ( { forumThreadReplyById }: any ): void => setBestReply( forumThreadReplyById )
  } )

  const [ getForumThreadReplies, {
    loading: loadingReplies,
    error:   errorLoadingReplies,
    refetch: refetchThreadReplies
  } ] = useLazyQuery( GET_FORUM_THREAD_REPLIES, {
    notifyOnNetworkStatusChange: true,
    onCompleted:                 ( { forumThreadReplies }: any ): void => {
      setForumThreadReplies( forumThreadReplies.data )
      setForumThreadRepliesPagination( forumThreadReplies.links )

      if ( forumThreadReplies.data.length > 0 ) {
        setHasReplies( true )
      } else {
        setHasReplies( null )
      }
    }
  } )

  const resolveSort: any = (): string => {
    switch ( sortBy ) {
      case 'oldestCreated':
        return 'asc'
      case 'newestCreated':
      case 'bestUpvote':
        return 'desc'
      default:
        return 'desc'
    }
  }

// todo: add sorting by upvote and create this functionality first
  const resolveSortBy: any = (): string => {
    switch ( sortBy ) {
      case 'bestUpvote':
        return 'votes'
      case 'oldestCreated':
      case 'newestCreated':
        return 'created_at'
      default:
        return 'created_at'
    }
  }

  const resolveParentReply: any = ( parentId: string ): any => {
    return forumThreadReplies.find( ( reply: any ): any => reply.id === parentId )
  }

  const handleAttachment: any = ( attachment: any ): any => {
    if ( attachment.type === 'application' ) {
      window.open( appCdnUrl + attachment.url, '_blank' ).focus()
    }

    if ( attachment.type === 'image' ) {
      setShowAttachment( attachment )
    }
  }

  const fetchForumThreadReplies: any = async ( page: number = 1 ): Promise<void> => {
    if ( isNaN( page ) ) page = 1

    setSearchParams( ( params: URLSearchParams ): URLSearchParams => {
      if ( page !== 1 ) {
        params.set( 'page', `${ page }` )
      } else {
        if ( params.has( 'page' ) ) params.delete( 'page' )
      }

      if ( sortBy !== 'newestCreated' ) {
        params.set( 'sortBy', sortBy )
      } else {
        if ( params.has( 'sortBy' ) ) params.delete( 'sortBy' )
      }

      return params
    } )

    await getForumThreadReplies( {
                                   variables: {
                                     input: {
                                       page,
                                       thread_id: forumThread.id,
                                       sort:      resolveSort(),
                                       sortBy:    resolveSortBy()
                                     }
                                   }
                                 } )
  }

  const getAuthorRank: any = ( rankId: string ): string => {
    const userRank: any = forumRanks.find( ( rank: any ): any => rank.id === rankId )
    return userRank ? userRank.name : 'Level 1'
  }

  const canModify: any = ( authorId: string ): boolean => {
    return (
      currentUser.admin ||
      currentUser.id === authorId
    )
  }

  useEffect( (): any => {
    let shouldFetch: boolean = true

    if ( shouldFetch ) {
      if ( !!forumThread?.best_reply_id ) {
        getForumThreadBestReply( { variables: { input: { id: forumThread.best_reply_id } } } )
      } else {
        setBestReply( null )
      }
    }

    return (): void => {
      shouldFetch = false
    }
  }, [ forumThread ] )

  useEffect( (): any => {
    let shouldFetchReplies: boolean = true

    if ( shouldFetchReplies ) {
      fetchForumThreadReplies( !forumThreadReplies ? ( parseInt( searchParams.get( 'page' ) ) ?? 1 ) : 1 ).catch( console.error )
    }

    return (): void => {
      shouldFetchReplies = false
    }
  }, [ sortBy ] )

  return (
    <>
      <div className="row flex flex-col items-center w-full bg-white rounded-xl p-4 lg:p-5">
        <div className="col flex w-full">
          <div className="flex">
            <div className="avatar rounded-full">
              <UserCircleIcon className="w-12 h-12" />
            </div>

            <div className="user ml-2">
              <div className="font-semibold ">
                { forumThread.author.name }
                <span className="text-gray-400 ml-2 text-sm">Author</span>
              </div>

              <div className="flex items-center text-muted text-gray-500 text-xs">
                { getAuthorRank( forumThread.author.forum_rank_id ) }<DotIcon className="w-5 h-5 p-[8.5px] text-black" />{ forumThread.author.forum_points } points
              </div>
            </div>
          </div>
        </div>

        <div className="col flex w-full">
          <div className="heading w-full">
            <h4 className="title text-2xl text-medium mt-4">{ forumThread.title }</h4>
            <p className="mt-2" dangerouslySetInnerHTML={ { __html: forumThread.body } } />
          </div>
        </div>

        <div className="col flex py-2 w-full">
          <div className="flex text-xs text-muted text-gray-500 w-full">Posted on { format( parseISO( forumThread.created_at ), 'do MMMM yyyy hh:mm a' ) }</div>
        </div>

        { forumThread.attachments?.length > 0 &&
          <div className="col grid grid-cols-4 lg:grid-cols-8 gap-2 lg:gap-4 w-full py-2">
            { forumThread.attachments.map( ( attachment: any ): any =>
                                             <CommunityForumThreadSingleAttachment
                                               classes="flex w-16 lg:w-24 h-full items-center justify-center cursor-pointer"
                                               attachment={ attachment }
                                               key={ attachment.id }
                                               handleAttachment={ (): any => handleAttachment( attachment ) }
                                             /> ) }
          </div>
        }

        { ( replyFormOpen !== 0 ) &&
          <div className="col flex w-full">
            <div className="flex rounded-full text-sm font-medium text-white overflow-hidden">
              <button
                type="button"
                className="w-full h-full px-3 py-1 flex items-center justify-center bg-blue-600 hover:bg-blue-500"
                onClick={ (): void => setReplyFormOpen( 0 ) }
              >
                <span>Reply</span>
              </button>
              { canModify( forumThread.id ) &&
                <Link to={ `/forum/edit/${ forumThread.id }` }>
                  <button type="button" className="w-full h-full px-3 py-1 flex items-center justify-center bg-blue-600 hover:bg-blue-500">
                    <span>Edit</span>
                  </button>
                </Link>
              }
            </div>
          </div>
        }

        { ( replyFormOpen === 0 ) &&
          <div className="col flex flex-col w-full mt-4">
            <CommunityForumThreadReplyForm
              forumThreadId={ forumThread.id }
              setReplyFormOpen={ setReplyFormOpen }
              refetch={ refetchThreadReplies }
            />
          </div>
        }
      </div>

      { ( loadingReplies || loadingBestReply ) && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }

      { ( !loadingReplies && !loadingBestReply ) && hasReplies &&
        <>
          { bestReply &&
            <div className="row flex items-center w-full bg-gray-50 rounded-xl p-4 lg:p-5">
              <div className="col flex flex-col w-full">
                <CommunityForumSingleReply
                  reply={ bestReply }
                  parent={ resolveParentReply( bestReply.parent_id ) }
                  bestReplyId={ bestReply.id }
                  setBestReply={ setBestReply }
                  getAuthorRank={ getAuthorRank }
                />
              </div>
            </div>
          }

          <div className="row flex flex-col items-center w-full bg-white rounded-xl px-4 pb-2 pt-2 lg:px-5">
            <div className="col flex w-full justify-between items-center mb-8 pb-2 text-xs text-gray-400 border-b border-gray-400">
                <div className="flex w-full">Replies: { forumThreadRepliesPagination.totalResults ?? forumThreadReplies.length }</div>

                <div className="flex w-full justify-end items-center">
                  Sort by:
                  <SelectOption
                    setSelected={ setSortBy }
                    selected={ selectedOption }
                    placeholder={ selectedOption.name }
                    selectClasses="text-xs font-bold border-none focus:ring-0"
                    options={ sortOptions.map( ( sort: any ): any => {
                      return { key: sort.id, value: sort.name }
                    } ) }
                  />
                </div>
            </div>

            <div className="col flex flex-col w-full">
              { forumThreadReplies.map( ( reply: any, index: any ): any => <CommunityForumThreadReply
                key={ reply.id }
                reply={ reply }
                refetch={ refetchThreadReplies }
                canModify={ canModify }
                parent={ resolveParentReply( reply.parent_id ) }
                replyFormOpen={ replyFormOpen }
                setReplyFormOpen={ setReplyFormOpen }
                bestReplyId={ forumThread.best_reply_id }
                setBestReply={ setBestReply }
                getAuthorRank={ getAuthorRank }
                isLast={ forumThreadReplies.length === ( index + 1 ) }
              /> ) }
            </div>

            { ( forumThreadRepliesPagination?.totalPages > 0 ) &&
              <Pagination
                className="justify-content-center pb-2 lg:pb-3"
                currentPage={ forumThreadRepliesPagination.page }
                totalCount={ forumThreadRepliesPagination.totalResults }
                pageSize={ forumThreadRepliesPagination.perPage }
                onPageChange={ ( page: number ): Promise<void> => fetchForumThreadReplies( page ) }
              /> }
          </div>
        </>
      }

      { !!showAttachment &&
        <CommunityForumModal
          open={ !!showAttachment }
          onClose={ (): void => setShowAttachment( null ) }
          content={
            <div className="thread-media-image">
              <button
                onClick={ async (): Promise<void> => await prefetchFileAndDownload( showAttachment.url, `${ showAttachment.name }.${ showAttachment.file_ext }` ) }
                className="absolute z-10 p-[10px] left-0 top-0 bg-white opacity-50 hover:opacity-75"
              >
                <ArrowDownTrayIcon className="w-5 h-5" />
              </button>
              <Image
                klass="thread-media-image"
                src={ appCdnUrl + showAttachment.url }
                alt={ showAttachment.name }
                defaultSrc={ assetFilePath( showAttachment.file_ext ) }
              />
            </div> }
        />
      }

      {
        ( !loadingReplies && !loadingBestReply ) && !hasReplies &&
        <div className="row flex items-center w-full bg-white rounded-xl p-4 lg:p-5">
          <div className="col flex flex-col w-full">
            There are no replies.
          </div>
        </div>
      }

      {
        errorLoadingReplies &&
        <div className="flex flex-col w-full bg-white rounded-xl p-4 lg:p-5">
          <b>Error occurred while fetching Forum Thread Replies:</b>
          { errorLoadingReplies.graphQLErrors.map( ( { message }: any, i: number ): any => (
            <div key={ i } className="flex w-full"><span>{ message }</span></div>
          ) ) }
        </div>
      }
</>
  )
}
