import { gql } from '@apollo/client'

// const ForumBadgeFragment: any = gql`
//     fragment ForumBadgeData on ForumBadge {
//         id
//         name
//         description
//         image
//     }
// `

const ForumCategoryFragment: any = gql`
    fragment ForumCategoryData on ForumCategory {
        id
        slug
        name
    }
`

const ForumRankFragment: any = gql`
    fragment ForumRankData on ForumRank {
        id
        level
        threshold
        name
        description
        image
    }
`

const ForumAttachmentFragment: any = gql`
    fragment ForumAttachmentData on ForumAttachment {
      id
      thread_id
      reply_id
      source
      type
      file_type
      file_ext
      name
      url
    }
`

const AuthorFragment: any = gql`
    fragment AuthorData on User {
        id
        roi_id
        name
        email
        admin
        forum_points
        forum_rank_id
    }
`

const ForumThreadReplyFragment: any = gql`
    fragment ForumThreadReplyData on ForumThreadReply {
        id
        thread_id
        author {
          ...AuthorData
        }
        author_id
        parent_id
        body
        attachments
        deleted_at
        created_at
        updated_at
    }
    ${ AuthorFragment }
`

const ForumThreadFragment: any = gql`
    fragment ForumThreadData on ForumThread {
        id
        title
        slug
        body
        best_reply_id
        category {
          ...ForumCategoryData
        }
        category_id
        attachments
        author {
          ...AuthorData
        }
        author_id
        views
        sticky
        resolved
        deleted_at
        created_at
        updated_at
    }
    ${ ForumCategoryFragment }
    ${ AuthorFragment }
`

const ForumThreadListFragment: any = gql`
    fragment ForumThreadListData on ForumThread {
        id
        title
        slug
        category_id
        author {
          name
        }
        author_id
        last_reply
        replies_count
        views
        sticky
        resolved
        deleted_at
        created_at
        updated_at
    }
`

const LinksFragment: any = gql`
    fragment LinksData on PaginatorLinks {
        totalResults
        totalPages
        page
        perPage
        prevPage
        nextPage
    }
`

// export const GET_FORUM_BADGES = gql`
//     query ForumBadge($input: QueryForumBadgesInput!) {
//         forumBadges(input: $input) {
//             ...ForumBadgeData
//         }
//     }
//     ${ ForumBadgeFragment }
// `

export const GET_FORUM_CATEGORIES = gql`
    query ForumCategories {
        forumCategories {
            ...ForumCategoryData
        }
    }
    ${ ForumCategoryFragment }
`

export const GET_FORUM_RANKS = gql`
    query ForumRank {
        forumRanks {
            ...ForumRankData
        }
    }
    ${ ForumRankFragment }
`

export const GET_FORUM_THREAD_LIST = gql`
    query ForumThreadList($input: QueryForumThreadListInput!) {
        forumThreadList(input: $input) {
          data {
            ...ForumThreadListData
          }
          links {
            ...LinksData
          }
        }
    }
    ${ ForumThreadListFragment }
    ${ LinksFragment }
`

export const CREATE_FORUM_ATTACHMENT = gql`
  mutation CreateForumAttachment($input: MutationCreateForumAttachmentInput!) {
    createForumAttachment(input: $input) {
      __typename
      ... on FieldError {
        field
        message
      }
      ... on MutationCreateForumAttachmentSuccess {
        data {
          ...ForumAttachmentData
        }
      }
    }
  }
  ${ ForumAttachmentFragment }
`

export const GET_FORUM_THREAD_ATTACHMENTS = gql`
  query ForumAttachments($input: QueryForumAttachmentsInput!) {
    forumAttachments(input: $input) {
      data {
        ...ForumAttachmentData
      }
      links {
        ...LinksData
      }
    }
  }
  ${ ForumAttachmentFragment }
  ${ LinksFragment }
`

export const GET_FORUM_THREAD_BY_ID = gql`
    query ForumThreadById($input: QueryForumThreadByIdInput!) {
        forumThreadById(input: $input) {
          ...ForumThreadData
        }
    }
    ${ ForumThreadFragment }
`

export const GET_FORUM_THREAD_REPLIES = gql`
  query ForumThreadReplies($input: QueryForumThreadRepliesInput!) {
    forumThreadReplies(input: $input) {
      data {
        ...ForumThreadReplyData
      }
      links {
        ...LinksData
      }
    }
  }
  ${ ForumThreadReplyFragment }
  ${ LinksFragment }
`

export const GET_FORUM_THREAD_REPLY_BY_ID: any = gql`
  query ForumThreadReplyById($input: QueryForumThreadReplyByIdInput!) {
    forumThreadReplyById(input: $input) {
      ...ForumThreadReplyData
    }
  }
  ${ ForumThreadReplyFragment }
`

export const CREATE_FORUM_THREAD: any = gql`
    mutation CreateForumThread($input: MutationCreateForumThreadInput!) {
      createForumThread(input: $input) {
        __typename
        ... on FieldError {
          field
          message
        }
        ... on MutationCreateForumThreadSuccess {
          data {
            ...ForumThreadData
          }
        }
      }
    }
    ${ ForumThreadFragment }
`

export const UPDATE_FORUM_THREAD: any = gql`
    mutation UpdateForumThread($input: MutationUpdateForumThreadInput!) {
      updateForumThread(input: $input) {
        __typename
        ... on FieldError {
          field
          message
        }
        ... on MutationUpdateForumThreadSuccess {
          data {
            ...ForumThreadData
          }
        }
      }
    }
    ${ ForumThreadFragment }
`

export const DELETE_FORUM_THREAD: any = gql`
    mutation DeleteForumThread($input: MutationDeleteForumThreadInput!) {
        deleteForumThread(input: $input) {
          __typename
          ... on FieldError {
            field
            message
          }
          ... on MutationDeleteForumThreadSuccess {
            data
          }
        }
    }
`

export const DELETE_FORUM_ATTACHMENT: any = gql`
    mutation DeleteForumAttachment($input: MutationDeleteForumAttachmentInput!) {
        deleteForumAttachment(input: $input) {
          __typename
          ... on FieldError {
            field
            message
          }
          ... on MutationDeleteForumAttachmentSuccess {
            data
          }
        }
    }
`

export const CREATE_FORUM_THREAD_REPLY: any = gql`
  mutation CreateForumReply($input: MutationCreateForumThreadReplyInput!) {
    createForumThreadReply(input: $input) {
      __typename
      ... on FieldError {
        field
        message
      }
      ... on MutationCreateForumThreadReplySuccess {
        data {
          ...ForumThreadReplyData
        }
      }
    }
  }
  ${ ForumThreadReplyFragment }
`

export const UPDATE_FORUM_THREAD_REPLY: any = gql`
  mutation UpdateForumThreadReply($input: MutationUpdateForumThreadReplyInput!) {
    updateForumThreadReply(input: $input) {
      __typename
      ... on FieldError {
        field
        message
      }
      ... on MutationUpdateForumThreadReplySuccess {
        data {
          ...ForumThreadReplyData
        }
      }
    }
  }
  ${ ForumThreadReplyFragment }
`

export const DELETE_FORUM_THREAD_REPLY: any = gql`
  mutation DeleteForumThreadReply($input: MutationDeleteForumThreadReplyInput!) {
    deleteForumThreadReply(input: $input) {
      __typename
      ... on FieldError {
        field
        message
      }
      ... on MutationDeleteForumThreadReplySuccess {
        data
      }
    }
  }
`
