import { useQuery }               from '@apollo/client'
import { useState }               from 'react'
import { useDispatch }            from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import CommunityForumThreadForm   from '../../components/Forum/CommunityForumThreadForm'
import { LoadingEllipsis }        from '../../components/Loaders/Loaders'
import { setToasts, showToaster } from '../../features/toaster/toasterSlice'
import { GET_FORUM_THREAD_BY_ID } from '../../graphql/communityForum'
import MainLayout                 from '../../layouts/MainLayout'

export default function CommunityForumEditThread(): JSX.Element {
  const { thread_id }                   = useParams()
  const [ forumThread, setForumThread ] = useState( null )
  const dispatch: any                   = useDispatch()
  const navigate: any                   = useNavigate()

  const { loading, error, refetch } = useQuery( GET_FORUM_THREAD_BY_ID, {
    variables:   { input: { id: thread_id, edit: true } },
    onCompleted: ( { forumThreadById }: any ): void => {
      if ( !forumThreadById ) {
        dispatchMessage( 'error', 'Question not found' )
        navigate( '/forum' )
      }

      setForumThread( forumThreadById )
    },
    onError:     ( error: any ): void => console.log( error )
  } )

  const dispatchMessage: any = ( type: string, message: string ): void => {
    dispatch( setToasts( [ { id: '1', type, message } ] ) )
    dispatch( showToaster( true ) )
  }

  return (
    <MainLayout>
      { loading && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4 mt-4" /> }
      { !loading &&
        <div className="max-w-screen-3xl mx-auto w-full">
          <div className="flex-grow lg:p-10 p-3 lg:space-y-8 space-y-3">

            { forumThread &&
              <div className="row flex flex-col items-center w-full bg-white rounded-xl p-4 lg:p-5">
                <div className="col flex flex-col w-full">
                  <CommunityForumThreadForm forumThread={ forumThread } refetch={ refetch } />
                </div>
              </div>
            }

            { error &&
              <div className="flex flex-col w-full bg-white rounded-xl p-4 lg:p-5">
                <b>Error occurred when fetching Forum Thread:</b>
                { error.graphQLErrors.map( ( { message }: any, i: number ): any => (
                  <div key={ i } className="flex w-full"><span>{ message }</span></div>
                ) ) }
              </div>
            }
          </div>
        </div>
      }
    </MainLayout>
  )
}
