// @ts-nocheck
import { useMutation }       from '@apollo/client'
import { TrashIcon }         from '@heroicons/react/24/outline'
import { format, parseISO }  from 'date-fns'
import { useState }          from 'react'
import Datepicker            from 'react-tailwindcss-datepicker'
import { Button, TextField } from '../../../components'
import { LoadingEllipsis }   from '../../../components/Loaders/Loaders'
import { Switch }            from '../../../components/Switch'
import {
  CREATE_DOCUMENT_FOLDER,
  DELETE_DOCUMENT_FOLDER,
  SEND_PUBLISHED_EMAIL_NOTIFICATIONS,
  UPDATE_DOCUMENT_FOLDER
}                            from '../../../graphql/document'

export default function FileForm( props: any ) {
  const { parentId, page, document } = props

  const [ updateDocument, { loading: updating } ] = useMutation( UPDATE_DOCUMENT_FOLDER, {
    onCompleted: () => window.location.href = '/documents'
  } )

  const [ sendPublishedEmailNotifications, { loading: sending } ] = useMutation( SEND_PUBLISHED_EMAIL_NOTIFICATIONS, {
    onCompleted: ( { sendPublishedEmailNotifications } ): void => {
      if ( sendPublishedEmailNotifications.__typename === 'FieldError' ) {
        console.log( 'sendPublishedEmailNotifications error: ', sendPublishedEmailNotifications.message )
        window.alert( 'Error! Email NOT sent! ' )
      } else {
        window.alert( 'Published Email Notifications sent' )
      }
    }
  } )

  const [ createDocument, { loading: creating } ] = useMutation( CREATE_DOCUMENT_FOLDER, {
    onCompleted: () => window.location.href = '/documents'
  } )

  const [ deleteDocumentFolder, { loading: deleting } ] = useMutation( DELETE_DOCUMENT_FOLDER, {
    onCompleted: () => window.location.href = '/documents'
  } )

  const [ title, setTitle ] = useState( document?.title ?? '' )
  const handleTitleChange   = ( newValue: any ) => setTitle( newValue )

  const [ publish, setPublish ] = useState( document ? !document.draft : false )
  const handlePublishChange     = ( newValue: any ) => setPublish( newValue )

  const [ aboutText, setAboutText ] = useState( document?.about ?? '' )
  const handleAboutTextChange       = ( newValue: any ) => setAboutText( newValue )

  const [ publishDate, setPublishDate ] = useState(
    { startDate: document?.publish ?? null, endDate: document?.publish ?? null }
  )
  const handlePublishDateChange         = ( newValue: any ) => setPublishDate( newValue )

  const handleSubmit = ( e: any ) => {
    e.preventDefault()

    const formData: any = {
      folder:  false,
      draft:   !publish,
      title:   title,
      about:   aboutText,
      publish: publishDate.startDate ? new Date( publishDate.startDate ).toISOString() : null
    }

    if ( document ) {
      formData.unique_id = document.unique_id
      formData.parent_id = document.parent_id

      updateDocument( { variables: { input: formData } } )
    } else {
      formData.parent_id = parentId

      createDocument( { variables: { input: formData } } )
    }
  }

  const handleDelete = ( e: any ) => {
    e.preventDefault()

    const confirmed: boolean = window.confirm( 'Are you sure you want to delete this document?' )
    if ( confirmed ) {
      deleteDocumentFolder( { variables: { input: { unique_id: document?.unique_id } } } )
    }
  }

  const handleNotification = async ( e: any ): Promise<void> => {
    e.preventDefault()

    const confirmed: boolean = window.confirm( 'Are you sure you want to notify ALL users about this document?' )

    if ( document && confirmed ) {
      try {
        const formData: any = {
          unique_id: document.unique_id,
          title:     document.title,
          page:      page
        }

        sendPublishedEmailNotifications( { variables: { input: formData } } )
      } catch ( error ) {
        console.log( error )
      }
    }
  }

  return (
    <>
    { ( updating || creating ) && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }
    { ( !updating || !creating ) &&
      <form className="mt-8 p-3" onSubmit={ handleSubmit }>
        <div className="flex flex-wrap items-center w-full mb-3">
          <div className="w-1/3">Published</div>
          <div className="w-2/3">
            <Switch
              checked={ publish }
              onChange={ handlePublishChange }
            />
          </div>
        </div>

        <div className="flex flex-wrap items-center w-full mb-3">
          <div className="w-1/3">Document Title</div>
          <div className="w-2/3">
            <TextField
              label=""
              value={ title }
              name="title"
              type="text"
              onChange={ handleTitleChange }
            />
          </div>
        </div>

        <div className="flex flex-wrap items-center w-full mb-3">
          <div className="w-1/3">About</div>
          <div className="w-2/3">
            <TextField
              label=""
              value={ aboutText }
              name="aboutText"
              type="text"
              onChange={ handleAboutTextChange }
            />
          </div>
        </div>

        <div className="flex flex-wrap items-center w-full mb-3">
          <div className="w-1/3">Publish Date</div>
          <div className="w-2/3">
            <Datepicker
              useRange={ false }
              asSingle={ true }
              value={ publishDate }
              onChange={ handlePublishDateChange }
            />
          </div>

          { document && document.notification_date && document.notification_sender_name &&
            <div className="flex flex-wrap justify-end w-full mt-6 -mb-3">
              <p className="text-gray-500 text-sm text-right">
                Email notification send at { format( parseISO( document.notification_date ), 'do MMMM yyyy' ) } by <em>{ document.notification_sender_name }</em>
              </p>
            </div>
          }

          <div className="flex flex-wrap items-center w-full mt-6 border-t-2 border-gray-300">
            <div className="flex w-1/3 pt-5">
              <Button submittable label="Submit" onAction={ () => {
              } } />
              { document &&
                <button
                  className="gap-x-2 px-3 py-1.5 text-sm w-full text-white font-medium flex items-center justify-center rounded-full text-center text-sm ml-2 bg-red-700 hover:bg-red-500"
                  onClick={ handleDelete }
                >
                  <TrashIcon className="w-4 h-4" />
                  <span>Delete</span>
                </button>
              }
            </div>
            { document && !document.notification_sender_uuid &&
              <div className="flex w-2/3 pt-5 justify-end">
                { sending && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }
                { !sending &&
                  <button
                    className="px-3 py-1.5 text-sm text-white font-medium flex items-center justify-center rounded-full text-center text-sm ml-2 bg-green-700 hover:bg-green-500"
                    onClick={ handleNotification }
                  >
                    <span>Notify Users</span>
                  </button>
                }
              </div>
            }
          </div>
        </div>
      </form>
    }
    </>
  )
}
