import { useMutation }               from '@apollo/client'
import { StarIcon, UserCircleIcon }  from '@heroicons/react/24/outline'
import { SparklesIcon }              from '@heroicons/react/24/solid'
import { format, parseISO }          from 'date-fns'
import { useSelector }               from 'react-redux'
// @ts-ignore
import { ReactComponent as DotIcon } from '../../assets/svgs/dot.svg'
import { LoadingEllipsis }           from '../../components/Loaders'
import { UPDATE_FORUM_THREAD }       from '../../graphql/communityForum'

export default function CommunityForumSingleReply( props: any ): JSX.Element {
    const { reply, bestReplyId, parent, setBestReply, getAuthorRank } = props

    const currentUser: any     = useSelector( ( state: any ) => state.currentUser.user )
    const canEdit: boolean     = currentUser.id === reply.author_id
    const isBestReply: boolean = bestReplyId && ( reply.id === bestReplyId )

    const [ updateForumThread, { loading } ] = useMutation( UPDATE_FORUM_THREAD )

    const markBestReply = (): any => {
        if ( window.confirm( 'Are you sure you want mark this replay as best?' ) ) {
            updateForumThread( { variables: { input: { id: reply.thread_id, best_reply_id: reply.id } } } )
            setBestReply( reply )
        }
    }

    const removeBestReply = (): any => {
        if ( window.confirm( 'Are you sure you want unmark this replay as best?' ) ) {
            updateForumThread( { variables: { input: { id: reply.thread_id, best_reply_id: '' } } } )
            setBestReply( null )
        }
    }

    return (
        <>
      <div className="flex justify-between">
        <div className="flex">
          <div className="avatar rounded-full">
            <UserCircleIcon className="w-12 h-12" />
          </div>
          <div className="user ml-2">
            <div className="font-semibold ">{ reply.author.name }</div>
            <div className="flex items-center text-muted text-gray-500 text-xs">
                { getAuthorRank( reply.author.forum_rank_id ) }<DotIcon className="w-5 h-5 p-[8.5px] text-black" />{ reply.author.forum_points } points
            </div>
          </div>
        </div>
          { isBestReply && !loading &&
            <div className="flex items-start text-gray-400">
                <div className={ `flex items-center${ canEdit ? ' cursor-pointer' : '' }` } onClick={ () => canEdit && removeBestReply() }>
                    <SparklesIcon className="w-5 h-5 mr-2" />
                    <span>Best reply</span>
                </div>
            </div>
          }
          { !isBestReply && !loading &&
            <StarIcon className={ `w-4 h-4 text-gray-400${ canEdit ? ' cursor-pointer' : '' }` } onClick={ () => canEdit && markBestReply() } />
          }
          { loading && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }
      </div>

      <div className="flex text-xs text-muted text-gray-500 py-1.5">{ format( parseISO( reply.created_at ), 'do MMMM yyyy hh:mm a' ) } in response to { parent ? parent.author.name : reply.author.name }</div>

      <div className="flex mt-4" dangerouslySetInnerHTML={ { __html: reply.body } } />
    </>
    )
}
