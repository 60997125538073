import { useLazyQuery, useQuery }                      from '@apollo/client'
import { ChatBubbleLeftRightIcon }                     from '@heroicons/react/24/outline'
import { debounce }                                    from 'lodash-es'
import { useEffect, useState }                         from 'react'
import { useDispatch, useSelector }                    from 'react-redux'
import { Link, useNavigate, useSearchParams }          from 'react-router-dom'
import { LoadingEllipsis }                             from '../../components/Loaders'
import SearchBar                                       from '../../components/Search/SearchBar'
import { SelectOption }                                from '../../components/Select/Select'
import { setForumCategories, setThreadList }           from '../../features/forum/communityForumSlice'
import { GET_FORUM_CATEGORIES, GET_FORUM_THREAD_LIST } from '../../graphql/communityForum'
import MainLayout                                      from '../../layouts/MainLayout'
import CommunityForumTable                             from './CommunityForumTable'

export default function CommunityForum(): JSX.Element {
  const dispatch: any                           = useDispatch()
  const navigate: any                           = useNavigate()
  const [ searchParams ]                        = useSearchParams()
  const [ page, setPage ]                       = useState( 1 )
  const [ forumCategoryId, setForumCategoryId ] = useState( '' )
  const [ sortBy, setSortBy ]                   = useState( 'latestActivityNewest' )
  const currentTenant: any                      = useSelector( ( state: any ) => state.currentTenant.tenant )
  const forumCategories: any                    = useSelector( ( state: any ) => state.communityForum.categories )
  const bodyInfo: string                        = currentTenant?.pages?.forum?.body ?? ''
  const headerText: string                      = currentTenant?.pages?.forum?.header ?? 'CommunityForum'

  const resolveSort = (): string => {
    switch ( sortBy ) {
      case 'latestActivityNewest':
      case 'dateCreatedNewest':
        return 'desc'
      case 'latestActivityOldest':
      case 'dateCreatedOldest':
        return 'asc'
      default:
        return 'desc'
    }
  }

  const resolveSortBy = (): string => {
    switch ( sortBy ) {
      case 'latestActivityNewest':
      case 'latestActivityOldest':
        return 'updated_at'
      case 'dateCreatedNewest':
      case 'dateCreatedOldest':
        return 'created_at'
      default:
        return 'updated_at'
    }
  }

  const [ getCategories, { loading: loadingCategories, error: errorLoadingCategories } ] = useLazyQuery(
    GET_FORUM_CATEGORIES, {
      notifyOnNetworkStatusChange: true,
      onCompleted:                 ( { forumCategories } ) => dispatch( setForumCategories( forumCategories ) )
    } )

  const { loading: loadingThreads, error: errorLoadingThreads, refetch } = useQuery(
    GET_FORUM_THREAD_LIST, {
      notifyOnNetworkStatusChange: true,
      variables:                   {
        input: {
          category_id: forumCategoryId,
          page:        ( page > 0 ) ? page : 1,
          sort:        resolveSort(),
          sortBy:      resolveSortBy()
        }
      },
      onCompleted:                 ( { forumThreadList } ) => dispatch( setThreadList( forumThreadList ) )
    } )

  const [ searchThreads, {
    data:    searchThreadList,
    loading: searchingThreads
  } ] = useLazyQuery( GET_FORUM_THREAD_LIST )

  const getSearchThreads = async ( query: string = '', page: number = 1 ): Promise<void> => {
    await searchThreads( {
                           variables: { input: { query, page, perPage: 15, sort: 'desc', sortBy: 'created_at' } }
                         } )
  }

  const debouncedSearch: any = debounce( async ( query: string, page: number ): Promise<any> => await getSearchThreads( query, page ), 2000 )

  const handleSearch = ( value: string ): void => {
    if ( value.length && value.trim().length >= 3 ) {
      debouncedSearch( value, 1 )
    }
  }

  const handleCategoryChange = ( categoryId: string ) => {
    if ( !categoryId ) {
      navigate( '/forum' )
    } else if ( forumCategoryId !== categoryId ) {
      navigate( `/forum?category=${ categoryId }` )
    }
  }

  useEffect( () => {
    let shouldFetchCategories: boolean = true

    if ( shouldFetchCategories && !forumCategories.length ) {
      getCategories()
    }

    return () => {
      shouldFetchCategories = false
    }
  }, [ forumCategories ] )

  useEffect( () => {
    const categoryParam = searchParams.get( 'category' )
    const sortByParam   = searchParams.get( 'sortBy' )
    const pageParam     = searchParams.get( 'page' )

    if ( categoryParam ) {
      setForumCategoryId( categoryParam )
    } else {
      setForumCategoryId( '' )
      if ( searchParams.has( 'category' ) ) {
        searchParams.delete( 'category' )
      }
    }

    if ( sortByParam ) {
      setSortBy( sortByParam )
    } else {
      setSortBy( 'latestActivityNewest' )
    }

    if ( pageParam ) {
      setPage( parseInt( pageParam ) )
    } else {
      setPage( 1 )
    }
  }, [ searchParams ] )

  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow lg:p-10 p-3 lg:space-y-8 space-y-3">

          <div className="row flex items-center w-full bg-white rounded-xl p-4 lg:p-5">
            <div className="col flex w-full">
              <div className="heading">
                <h4 className="card-title mb-0 text-xl text-medium">{ headerText }</h4>
                <p className="text-muted text-sm text-gray-500">{ bodyInfo }</p>
              </div>
            </div>
          </div>

          <SearchBar
            resultsInModal
            loading={ searchingThreads }
            results={ searchThreadList?.forumThreadList?.data ?? null }
            pagination={ searchThreadList?.forumThreadList?.links ?? null }
            onChange={ handleSearch }
            setPage={ getSearchThreads }
            placeholder="Search forum"
            type="forum"
          />

          <div className="row flex flex-col items-center w-full bg-white rounded-xl p-4 lg:p-5">
            <div className="col flex justify-between w-full mb-4">
              <SelectOption
                setSelected={ handleCategoryChange }
                selected={ forumCategoryId }
                placeholder="Category (All)"
                options={ forumCategories.map( ( cat: any ) => {
                  return { key: cat.id, value: cat.name }
                } ) }
              />
              <Link to={ `/forum/add` } className="flex justify-end items-center py-2 px-4 text-white bg-blue-secondary hover:bg-blue-secondary-darker rounded-full">
                <span>Add new question</span>
                <ChatBubbleLeftRightIcon className="w-5 h-5 ml-2" />
              </Link>
            </div>

            <div className="col flex flex-col w-full">
              { ( loadingThreads || loadingCategories ) &&
                <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4 mt-4 w-full" />
              }

              { ( !loadingThreads && !loadingCategories ) &&
                <CommunityForumTable
                  categoryId={ forumCategoryId }
                  forumCategories={ forumCategories }
                  refetch={ () => refetch() }
                  sortBy={ sortBy }
                />
              }
            </div>
          </div>

          { errorLoadingCategories &&
            <div className="flex flex-col w-full bg-white rounded-xl p-4 lg:p-5">
              <b>Error occurred when fetching Forum Categories:</b>
              { errorLoadingCategories.graphQLErrors.map( ( { message }, i: number ) => (
                <div key={ ( i + 1000 ) } className="flex w-full"><span>{ message }</span></div>
              ) ) }
            </div>
          }

          { errorLoadingThreads &&
            <div className="flex flex-col w-full bg-white rounded-xl p-4 lg:p-5">
              <b>Error occurred when fetching Forum Thread List:</b>
              { errorLoadingThreads.graphQLErrors.map( ( { message }, i: number ) => (
                <div key={ ( i + 2000 ) } className="flex w-full"><span>{ message }</span></div>
              ) ) }
            </div>
          }
        </div>
      </div>
    </MainLayout>
  )
}
