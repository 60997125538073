import { BellIcon, MagnifyingGlassIcon }      from '@heroicons/react/20/solid'
import classNames                             from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useSearchParams }                    from 'react-router-dom'
import { Modal }                              from '../../components/Modal'
import Pagination                             from '../../components/Pagination/Pagination'
import Notifications                          from '../Notification/Notifications'
import RecentSearches                         from './RecentSearches'
import SearchResults                          from './SearchResults'

interface SearchBarProps
{
  placeholder?: string
  resultsInModal?: boolean
  results?: any
  pagination?: any
  loading?: boolean
  onChange?: ( query: string ) => void
  setPage?: ( query: string, page: number ) => void
  type: string
}

export default function SearchBar( props: SearchBarProps ) {
  const { placeholder, resultsInModal, results, pagination, setPage, onChange, loading, type } = props
  const [ searchParams, setSearchParams ]                                                      = useSearchParams()
  const [ query, setQuery ]                                                                    = useState( '' )
  const [ openModal, setOpenModal ]                                                            = useState( false )
  const [ openNotifications, setOpenNotifications ]                                            = useState( false )
  const navRef: any                                                                            = useRef()
  const modalInputRef: any                                                                     = useRef()

  const handleChange = ( searchQuery: string ) => {
    setQuery( searchQuery )

    setSearchParams( params => {
      params.set( 'q', searchQuery )
      return params
    } )

    onChange( searchQuery )
  }

  // Used when navigating away, we want to keep search query.
  const handleCloseModal = () => {
    setQuery( '' )
    setOpenModal( false )
  }

  // When closing the modal, we also remove the search query.
  const handleCloseModalRemoveQuery = () => {
    if ( searchParams.has( 'q' ) ) {
      searchParams.delete( 'q' )
      setSearchParams( searchParams )
    }

    handleCloseModal()
  }

  useEffect( function setModalInputFocus() {
    if ( openModal && resultsInModal ) {
      setTimeout( () => {
        modalInputRef?.current?.focus()
      }, 300 )
    }
  }, [ openModal ] )

  /**
   *  Keep modal open when query param in URL so that the user can continue with their search.
   */
  useEffect( function openModalWhenQueryInURL() {
    const q = searchParams.get( 'q' )
    if ( q && resultsInModal ) {
      setOpenModal( true )
      handleChange( q )
    }
  }, [ searchParams ] )

  const searchModalMarkup = (
    <div className="lg:w-[40rem] md:w-[30rem] w-72 flex flex-col flex-grow">
      <div className="flex items-center p-3">
        <MagnifyingGlassIcon className="w-5 h-5" />

        <form className="w-full" onSubmit={ ( event ) => event.preventDefault() }>
          <input
            ref={ modalInputRef }
            type="search"
            autoComplete="off"
            value={ query }
            onChange={ ( e: any ) => handleChange( e.target.value ) }
            placeholder={ placeholder }
            className="pl-3 text-sm font-medium w-full border-none border-transparent focus:border-transparent focus:ring-0"
          />
        </form>
      </div>

      { query.length ? (
        <SearchResults onClose={ handleCloseModal } loading={ loading } results={ results } query={ query } type={ type } /> ) : null }

      { !query.length ? (
        <RecentSearches onClose={ handleCloseModal } loading={ loading } query={ query } type={ type } /> ) : null }

      { !!query.length && ( pagination?.totalPages > 0 ) &&
        <Pagination
          className="justify-content-center mb-4"
          currentPage={ pagination.page }
          totalCount={ pagination.totalResults }
          pageSize={ pagination.perPage }
          onPageChange={ ( page: any ) => setPage( query, page ) }
        />
      }
    </div>
  )

  return (
    <>
      <div className="SearchBar flex items-center w-full bg-white rounded-full p-2">
        <div className="flex-1 bg-blue-subtle rounded-full flex items-center px-3 relative">
          <MagnifyingGlassIcon className="w-5 h-5" />

          <form action={ process.env.REACT_APP_V1_HOST + '/search' } method="get" className="w-full rounded-full">
            <input
              type="search"
              placeholder={ placeholder || 'Search' }
              required={ true }
              autoComplete="off"
              name="q"
              value={ resultsInModal ? '' : query }
              onClick={ () => resultsInModal ? setOpenModal( true ) : {} }
              onChange={ ( e: any ) => setQuery( e.target.value ) }
              className={ classNames( 'w-full bg-transparent border-none border-transparent focus:border-transparent focus:ring-0 pl-3 text-sm font-medium',
                                      { 'cursor-pointer select-none': resultsInModal } ) }
            />
          </form>
        </div>

        <div ref={ navRef } className="pl-6">
          <button
            title="Notifications"
            onClick={ () => setOpenNotifications( !openNotifications ) }
            className="w-8 h-8 rounded-full flex items-center justify-center hover:bg-gray-100 focus:ring-2 focus:ring-gray-200"
          >
            <BellIcon className="w-5 h-5" />
          </button>
        </div>
      </div>

      <Notifications
        ref={ navRef }
        open={ openNotifications }
        onAction={ setOpenNotifications }
      />

      <Modal
        fit
        top={ resultsInModal }
        maxHeight={ 'max-content' }
        flush
        isOpen={ openModal }
        onClose={ handleCloseModalRemoveQuery }
        content={ searchModalMarkup }
      />
    </>
  )
}
