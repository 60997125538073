import { createSlice } from '@reduxjs/toolkit'

export const communityForumSlice = createSlice( {
  name:         'communityForum',
  initialState: {
    data:         [],
    categories:   [],
    ranks:        [],
    pagination:   {},
    refetch:      false,
    ranksRefetch: false,
    threadAttachmentsRefetch: false
  },
  reducers:     {
    setForumCategories:  ( state, action ) => {
      state.categories = action.payload
    },
    setForumRanks:       ( state, action ) => {
      state.ranks = action.payload
    },
    setForumListRefetch: ( state, action ) => {
      state.refetch = action.payload
    },
    setThreadAttachmentsRefetch: ( state, action ) => {
      state.threadAttachmentsRefetch = action.payload
    },
    setThreadList:       ( state, action ) => {
      state.data       = action.payload.data
      state.pagination = action.payload.links
    }
  }
} )

export const {
               setForumCategories,
               setForumRanks,
               setForumListRefetch,
               setThreadAttachmentsRefetch,
               setThreadList
             } = communityForumSlice.actions

export default communityForumSlice.reducer
