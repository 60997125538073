export const MonthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const WeekDays = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

export const getDayName = (date) => {
  const dayIndex = date.getDay();
  return WeekDays[dayIndex];
}

const daysOfWeek = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

export const getAbbreviation = (day) => { // day: Wednesday, Friday etc
  const formattedDay = day.substring(0, 2).toUpperCase(); // Taking the first two letters and converting to uppercase
  const index = daysOfWeek.indexOf(formattedDay);
  if (index !== -1) {
    return daysOfWeek[index];
  } else {
    return "Invalid day";
  }
}

export const truncateText = ( q, limit = 100, ending = '...' ) => {
  if ( q.length <= 100 ) return q

  const lastSpace = q.slice( 0, limit - ending.length + 1 ).lastIndexOf( ' ' )

  return q.slice( 0, lastSpace > 0 ? lastSpace : limit - ending.length ) + ending
}

const hexColours = [
  {a: '#ef4444'},
  {b: '#f97316'},
  {c: '#65a30d'}, // lime
  {d: '#2563eb'}, // blue
  {e: '#9333ea'}, // voilet
  {f: '#9333ea'}, // purple
  {g: '#c026d3'}, // fuchsia
  {h: '#ca8a04'}, // yellow
  {i: '#0284c7'}, // sky
  {j: '#ca8a04'}, // yellow
  {k: '#dc2626'}, // red
  {l: '#ea580c'}, // orange
  {m: '#65a30d'}, // lime
  {n: '#d97706'}, // amber
  {o: '#65a30d'}, // lime
  {p: '#2563eb'}, // blue
  {q: '#2563eb'}, // blue
  {r: '#9333ea'}, // pruple
  {s: '#2563eb'}, // blue
  {t: '#d97706'}, // amber
  {u: '#65a30d'}, // lime
  {v: '#2563eb'}, // blue
  {w: '#d97706'}, // amber
  {x: '#65a30d'}, // lime
  {y: '#9333ea'}, // pruple
  {z: '#e11d48'}, // rose
]

export const setPrevUrl = ( url ) => {
  localStorage.setItem( 'prevUrl', url )
}

export const getPrevUrl = ( url ) => {
  return localStorage.getItem( 'prevUrl' )
}

/**
 * Generates a random string values.
 *
 * @returns {string}
 */
export const GENERATE_UUID = () => {
    let S4 = function() {
        return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
     };
     return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

export const GetInitials = (name) => {
  if (!name) { return name}
    const names = name.split(' ');
    let initials = '';
    for (let i = 0; i < names.length; i++) {
      if (names[i].length > 0) {
        initials += names[i][0].toUpperCase();
      }
      if (initials.length === 3) {
        break;
      }
    }
    return initials;
}

export const TruncateString = (str, length) => {
  if (str.length > length) {
    return str.slice(0, length) + '...';
  } else return str;
}

export const StripHtmlTags = (str) => {
  if (!str) { return str }
  return str.replace(/<[^>]*>/g, '');
}

export const FormatDate = (str, format=null) => {
  const date = containsNumbers(str) ? new Date(parseInt(str) * 1000) : new Date(str);

  if (isNaN(date.getTime())) {
    return str;
  }

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedHours = hours % 12 || 12; // handle midnight
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const ampm = hours >= 12 ? 'PM' : 'AM';


 switch (format) {
    case 'hh:mmTT':
      return `${formattedHours}:${formattedMinutes}${ampm}`;
    case 'dd/MM/yyyy hh:mmTT':
      return `${day}/${monthIndex + 1}/${year} ${formattedHours}:${formattedMinutes}${ampm}`;
    case 'D MMMM yyyy at hh:mm':
      return `${day} ${MonthNames[monthIndex]} ${year} at ${formattedHours}:${formattedMinutes}`;
    default:
      return `${day}/${monthIndex + 1}/${year}`;
  }
}

export const CountWords = (str) => {
  // Remove HTML tags and attributes
  str = StripHtmlTags(str);

 // if (!str) { return 0 }

  // Count the words
  const words = str.match(/\b\w+\b/g);
  return words ? words.length : 0;
}

export const RemoveUnnecessaryTags = (input) => {
  if (!input) { return '' }
  // Use a regular expression to match the unnecessary tags
  const regex = /<style.*?<\/style>|<meta.*?>|<link.*?>/gs;

  // Use the replace method to remove the matched tags
  const output = input.replace(regex, '');

  return output;
}


export const AvatarColour = (name) => {
  const n = name ? name[0].toLowerCase() : 'o';

  return hexColours.find(x => x[n])[n];
}

export const PLUCK = (arr, key) => {
  return arr.map((item) => item[key]);
}

export const formatBytes = (bytes) => {
  if (bytes === 0) {
    return '0 B';
  }

  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${(bytes / Math.pow(k, i)).toFixed(2)} ${sizes[i]}`;
}

export const formatDateForURL = (url, dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  if (isNaN(date.getTime())) {
    return ""; // Invalid date string, return an empty string or handle the error as needed
  }

  if (dateString.includes("T")) {
    // If the input string contains a time component, return both date and start_time
    return `${url}?date=${year}-${month}-${day}&start_time=${hours}:${minutes}`;
  } else {
    // If there's no time component, return just the date
    return `${url}?date=${year}-${month}-${day}`;
  }
}

/**
 * System Mapper Colours for Tailwind
 *
 * @see https://tailwindcss.com/docs/customizing-colors
 */
export const systemColours = {
  '#4f46e5': {
    name: 'indigo-600',
    divide: 'divide-indigo-500',
    cta: 'bg-indigo-800 text-white',
    secondary: 'text-indigo-200'
  },

  '#db2777': {
    name: 'pink-600',
    divide: 'divide-pink-500',
    cta: 'bg-pink-700 text-white',
    secondary: 'text-pink-500'
  },

  '#ea580c': {
    name: 'orange-600',
    divide: 'divide-orange-500',
    cta: 'bg-orange-800 text-white',
    secondary: 'text-orange-200'
  },

  '#9ca3af': {
    name: 'gray-400',
    divide: 'divide-gray-200',
    cta: 'bg-gray-500 text-black',
    secondary: 'text-gray-100'
  },

  '#2563eb': {
    name: 'blue-600',
    divide: 'divide-blue-500',
    cta: 'bg-blue-600 text-white',
    secondary: 'text-blue-200'
  },

  '#dc2626': {
    name: 'red-600',
    divide: 'divide-red-500',
    cta: 'bg-red-700 text-white',
    secondary: 'text-red-200'
  },
  // This blue was taken from https://www.bananamoondaynursery.co.uk/
  '#0091da': {
    name: 'banana-500',
    divide: 'divide-blue-400',
    cta: 'bg-white text-blue-500',
    secondary: 'text-blue-300'
  }
}

export const prefetchFileAndDownload = async ( path, name ) => {
  const file      = `${ process.env.REACT_APP_UPLOAD_HOST }/download/${ path.split( '/' ).join( '~' ) }`
  const blob      = await fetch( file ).then( r => r.blob() )
  const url       = URL.createObjectURL( blob )
  const a         = document.createElement( 'a' )
  a.href          = url
  a.download      = name
  a.click()
  window.URL.revokeObjectURL( url )
}

export const formatFilePathForView = (path) => {
  if (!path) return

  // Our upload services requires this format as '/' will cause an issue in the URL
  const newPath =  path.split('/').join('~')

  return `${process.env.REACT_APP_UPLOAD_HOST}/download/${newPath}`
}

export const assetFilePath = (extension) => {
  let ext = extension || ''

  const supportedExts = ['jpg', 'jpeg', 'png', 'pdf', 'docx', 'doc', 'wordprocessingml.document', 'spreadsheetml.sheet', 'presentationml.presentation', 'pptx'];

  if ((ext && supportedExts.includes(ext)) ||
      ext.includes('wordprocessingml.document') ||
      ext.includes('spreadsheetml.sheet') ||
      ext.includes('presentationml.presentation')
      ) {

    if (ext === 'doc' || ext === 'docx' || ext.includes('wordprocessingml.document')) {
        ext = 'doc'
    }

    if (ext === 'jpg' || ext === 'jpeg' || ext === 'jpeg' || ext === 'gif' || ext === 'png') {
      ext = 'image'
    }

    if (ext.includes('spreadsheetml.sheet')) {
      ext = 'xls'
    }

    if (ext.includes('presentationml.presentation') || ext === 'pptx') {
      ext = 'pp'
    }

    return `${process.env.REACT_APP_CDN}/assets/file-${ext}.png`;
  }

  // Return a default URL or handle unsupported extensions as needed
  return `${process.env.REACT_APP_CDN}/assets/file-default.png`;
}

export const getSystemColourObjectByKey = (key) => {
  return systemColours[key] || systemColours['#2563eb'];
}

function containsNumbers(str) {
  return !isNaN(str) && !isNaN(parseFloat(str));
}
