/**
 * To convert to HTML and vice-versa, see: https://lexical.dev/docs/concepts/serialization
 */
import { useEffect, forwardRef, useState, memo } from 'react';
import {$generateHtmlFromNodes, $generateNodesFromDOM  } from '@lexical/html';
import StandardTheme from "./themes/StandardTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import TreeViewPlugin from "./plugins/TreeViewPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import ImagePlugin from "./plugins/ImagePlugin";
import { ImageNode } from "./nodes/ImageNode";
import HtmlPlugin from './plugins/HtmlPlugin';
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import {ClearEditorPlugin} from '@lexical/react/LexicalClearEditorPlugin';
import { TRANSFORMERS } from "@lexical/markdown";

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";

import { ExtendedTextNode } from './classes';
import { TextNode } from 'lexical';


import './Editor.scss';

const editorConfig = {
  // The editor theme
  theme: StandardTheme,
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    ExtendedTextNode,
    { replace: TextNode, with: (node) => new ExtendedTextNode(node.__text, node.__key) },
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
    ImageNode
  ]
};

 export const Editor = forwardRef((props, ref) => {
  const [content, setContent] = useState(props.initialHtml);
    // See: https://codesandbox.io/s/lexical-rich-text-example-26zxky?file=/src/Editor.js:2252-2272
    const onChange = (editorState, editor) => {
        //console.log(editorState)
        editor.update(() => {
            const htmlString = $generateHtmlFromNodes(editor, null);
           // props.onChange(htmlString)
           setContent(htmlString)
        });


    }

    const Placeholder = () => {
      return <div className="editor-placeholder">{ props.placeholder }</div>;
    }

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="editor-container w-full flex flex-col flex-grow">
        <ToolbarPlugin directImageUpload={ props.directImageUpload } />

        {/* <ImageToolbarPlugin /> */}
        <div className={ `editor-inner w-full flex-grow${ props.errorField ? ' border border-red-500' : '' }` }>
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
           <HtmlPlugin
            onHtmlChanged={() => {} }
            initialHtml={ props.initialHtml }
            />
          <OnChangePlugin onChange={onChange} />
          <ClearEditorPlugin />
          <ImagePlugin />
          {/* <MyCustomAutoFocusPlugin /> */}
          <HistoryPlugin />
          {/* <TreeViewPlugin /> */}
          {/* <AutoFocusPlugin /> */}
          <CodeHighlightPlugin />
          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        </div>

        { props.actionNode && (
          props.actionNode(content)
        ) }

        {/* <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-x-2">
                <Button
                    auto
                    label="Send email"
                    onClick={() => {
                      props.onSubmit()

                    }}
                />
                <Button
                    auto
                    clear
                    label="Save draft"
                    onClick={() => {}}
                />

                <PaperClipIcon className="w-5 h-5" />
            </div>

            <div className="flex justify-center items-center gap-x-4">
               { props.expandable ? (
                    <Link to="/messages/new" className="hover:bg-gray-100 w-7 h-7 flex items-center justify-center border rounded-full p-1">
                        <ArrowsPointingOutIcon className="w-5 h-5" />
                    </Link>
               ) : null}
                <button className="hover:bg-gray-100 w-7 h-7 flex items-center justify-center border rounded-full p-1">
                    <TrashIcon  />
                </button>
            </div>
        </div> */}


      </div>
    </LexicalComposer>
  );
})//, (prev, next) => prev !== next)
