import CommunityForumThreadForm        from '../../components/Forum/CommunityForumThreadForm'
import MainLayout                      from '../../layouts/MainLayout'

export default function CommunityForumNewThread(): JSX.Element {
  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow lg:p-10 p-3 lg:space-y-8 space-y-3">

          <div className="row flex items-center w-full bg-white rounded-xl p-4 lg:p-5">
            <div className="col flex w-full">
              <div className="heading">
                <h4 className="title mb-0 text-xl text-medium">New Question</h4>
                <p className="text-muted text-sm text-gray-500">add a question here </p>
              </div>
            </div>
          </div>

          <div className="row flex flex-col items-center w-full bg-white rounded-xl p-4 lg:p-5">
            <div className="col flex flex-col w-full">
              <CommunityForumThreadForm />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
