import React, { useState }                  from 'react'
import CommunityForumSingleReply            from '../../components/Forum/CommunityForumSingleReply'
import CommunityForumThreadReplyForm        from '../../components/Forum/CommunityForumThreadReplyForm'
import { assetFilePath }                    from '../../utilities'
import { Image }                            from '../Image'
import CommunityForumModal                  from '../Modal/CommunityForumModal'
import CommunityForumThreadSingleAttachment from './CommunityForumThreadSingleAttachment'

export default function CommunityForumThreadReply( props: any ): JSX.Element {
  const {
          reply,
          bestReplyId,
          parent,
          replyFormOpen,
          setReplyFormOpen,
          setBestReply,
          canModify,
          refetch,
          getAuthorRank,
          isLast
        } = props

  const [ showAttachment, setShowAttachment ] = useState( null )
  const [ isEdit, setIsEdit ]                 = useState( null )
  const appCdnUrl: string                     = process.env.REACT_APP_CDN + ( process.env.REACT_APP_CDN.slice( -1 ) === '/' ? '' : '/' )

  const handleReplyForm: any = ( replyId: string | null, isEdit: boolean = false ): void => {
    setReplyFormOpen( replyId )
    setIsEdit( isEdit )
  }

  const handleAttachment: any = ( attachment: any ): any => {
    if ( attachment.type === 'application' ) {
      window.open( appCdnUrl + attachment.url, '_blank' ).focus()
    }

    if ( attachment.type === 'image' ) {
      setShowAttachment( attachment )
    }
  }

  return (
    <div className={ `thread-reply ${ isLast ? 'mb-4' : 'mb-16' }` }>
      <CommunityForumSingleReply
        reply={ reply }
        parent={ parent }
        bestReplyId={ bestReplyId }
        setBestReply={ setBestReply }
        getAuthorRank={ getAuthorRank }
      />

      { reply.attachments?.length > 0 &&
        <div className="col grid grid-cols-4 lg:grid-cols-8 gap-2 lg:gap-4 w-full py-2">
          { reply.attachments.map( ( attachment: any ): any =>
                                     <CommunityForumThreadSingleAttachment
                                       classes="flex w-16 lg:w-24 h-full items-center justify-center cursor-pointer"
                                       attachment={ attachment }
                                       key={ attachment.id }
                                       handleAttachment={ (): any => handleAttachment( attachment ) }
                                     /> ) }
        </div>
      }

      { ( replyFormOpen !== reply.id ) &&
        <div className="col flex pt-4 w-full">
          <div className="flex rounded-full text-xs font-medium text-blue-600 border border-blue-600 overflow-hidden">
            <button
              type="button"
              className="w-full h-full px-2.5 py-1 flex items-center justify-center hover:bg-blue-600 hover:text-white"
              onClick={ (): any => setReplyFormOpen( reply.id ) }
            >
              <span>Reply</span>
            </button>

            { canModify( reply.author_id ) &&
              <button
                type="button"
                className="w-full h-full px-2.5 py-1 flex items-center justify-center hover:bg-blue-600 hover:text-white"
                onClick={ (): any => handleReplyForm( reply.id, true ) }
              >
                <span>Edit</span>
              </button>
            }
          </div>
        </div>
      }

      { ( replyFormOpen === reply.id ) &&
        <div className="col flex flex-col w-full mt-4">
          <CommunityForumThreadReplyForm
            forumThreadId={ reply.thread_id }
            parentId={ reply.id }
            setReplyFormOpen={ handleReplyForm }
            forumThreadReply={ isEdit ? reply : null }
            refetch={ refetch }
          />
        </div>
      }

      { !!showAttachment &&
        <CommunityForumModal
          open={ !!showAttachment }
          onClose={ (): void => setShowAttachment( null ) }
          content={ <Image
            klass="thread-media-image"
            src={ appCdnUrl + showAttachment.url }
            alt={ showAttachment.name }
            defaultSrc={ assetFilePath( showAttachment.file_ext ) }
          /> }
        />
      }
    </div>
  )
}
